import React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import Layout from './src/components/layout';
import SessionCheck from './src/components/shared/session-check';
import { silentAuth } from './src/utils/auth';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => <SessionCheck>{element}</SessionCheck>;

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => <Layout {...props}>{element}</Layout>;

export const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] =
  () => {
    silentAuth(() => {});
  };
