import React, { CSSProperties } from 'react';

interface IconProps {
  SvgIcon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  currentColor?: string;
  [x: string]: any; // for other props that might be passed to the SVG
}

const Icon: React.FC<IconProps> = ({ SvgIcon, currentColor, ...props }) => {
  const styles: CSSProperties = {
    stroke: currentColor,
  };

  return <SvgIcon style={styles} {...props} />;
};

export default Icon;
