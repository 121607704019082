import React from 'react';
import { Link } from 'gatsby';

function BottomMenu() {
  return (
    <div className="container">
      <div className="columns">
        <div className="column">
          <h3 className="title is-5">Služby</h3>
          <ul>
            <li>
              <Link to="/sluzby/">Všechny služby</Link>
            </li>
            <li>
              <Link to="/trigger/">Komunita Trigger</Link>
            </li>
            <li>
              <Link to="/nocode-vzdelavani/">No-code vzdělávání</Link>
            </li>
            <li>
              <a
                href="https://forum.workflow.ooo"
                target="_blank"
                rel="noreferrer"
              >
                Poradna
              </a>
            </li>
            <li>
              <Link to="/muj-ucet/">Můj účet</Link>
            </li>
          </ul>
        </div>

        <div className="column">
          <h3 className="title is-5">Obsah</h3>
          <ul>
            {/* <li>
              <Link to="/realizovane-zakazky/">Realizované zakázky</Link>
            </li> */}
            <li>
              <Link to="/newsletter/">Newsletter</Link>
            </li>
            <li>
              <Link to="/navody-inspirace/">Návody, inspirace a ukázky</Link>
            </li>
            <li>
              <Link to="/nastroje/">Nástroje</Link>
            </li>
            <li>
              <Link to="/pojmy/">Pojmy</Link>
            </li>
            <li>
              <a href="https://twitter.com/honzapav">Twitter</a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCvVuHg-OJGTUwTX7TBAVB6g"
                target="_blank"
                rel="noreferrer"
              >
                YouTube kanál
              </a>
            </li>
          </ul>
        </div>
        <div className="column">
          <h3 className="title is-5">O Workflow</h3>
          <ul>
            <li>
              <Link to="/o-workflow/">O Workflow</Link>
            </li>

            <li>
              <Link to="/napoveda/">Nápověda</Link>
            </li>
            <li>
              <a
                href="https://forum.workflow.ooo/t/zpetna-vazba-k-obsahu-nebo-fungovani-webu-workflow/27"
                target="_blank"
                rel="noreferrer"
              >
                Zpětná vazba
              </a>
            </li>
          </ul>
        </div>
        <div className="column">
          <h3 className="title is-5">Kontakty</h3>
          <ul>
            <li>
              <Link to="/kontakt/">Kontakty</Link>
            </li>
            <li>
              <a href="https://honzapav.cz" target="_blank" rel="noreferrer">
                HonzaPav.cz
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default BottomMenu;
