import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="content">
          <strong>Workflow</strong> by{' '}
          <a href="https://honzapav.cz" target="_blank" rel="noreferrer">
            Honza Pav
          </a>{' '}
          |{' '}
          <a
            href="https://honzapav.cz/osobni-udaje/"
            target="_blank"
            rel="noreferrer"
          >
            Osobní údaje
          </a>{' '}
          | &copy; {new Date().getFullYear()}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
