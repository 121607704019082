import React from 'react';
import { silentAuth } from '../../utils/auth';

type PageProps = {
  children: React.ReactNode;
};

class SessionCheck extends React.Component<PageProps> {
  constructor(props: PageProps) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    silentAuth(this.handleCheckSession);
  }

  handleCheckSession = () => {
    this.setState({ loading: false });
  };

  render() {
    const { children } = this.props;
    return children;
  }
}

export default SessionCheck;
