import React from 'react';
import PropTypes from 'prop-types';

function Hamburger({ onClick, isOpen }) {
  return (
    <div className="navbar-burger-block">
      <span
        className="navbar-burger-text"
        style={{ margin: 'auto' }}
        aria-label="menu"
        aria-expanded={!isOpen}
        aria-hidden="true"
        onClick={onClick}
      >
        Menu
      </span>
      <button
        type="button"
        className={`navbar-burger ${isOpen ? 'is-active' : ''}`}
        style={{ backgroundColor: 'rgba(0,0,0,0)' }}
        aria-label="menu"
        aria-expanded={!isOpen}
        aria-hidden="true"
        id="burger"
        onClick={onClick}
        tabIndex="0"
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </button>
    </div>
  );
}

export default Hamburger;

Hamburger.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
