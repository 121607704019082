import React, { useState } from 'react';
import { Link } from 'gatsby';
import Hamburger from './hamburger';
import HomeIcon from '../assets/icons/home.svg';
import ServiceIcon from '../assets/icons/service.svg';
import Logo from '../assets/logo/wokflow-logo.svg';
import CheckIcon from '../assets/icons/check.svg';
import ArrowRightIcon from '../assets/icons/arrow-right.svg';
import ToolboxIcon from '../assets/icons/toolbox.svg';
import UsersIcon from '../assets/icons/users.svg';
import PhoneIcon from '../assets/icons/phone.svg';
import MedalIcon from '../assets/icons/medal.svg';
import Icon from './shared/icon';

function Nav() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((isOpen) => !isOpen);
  };

  return (
    <div className="container">
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a id="logo" href="/">
            <Logo />
          </a>
          <Hamburger onClick={toggleMenu} isOpen={isMenuOpen} />
        </div>
        <div
          id="nav-links"
          className={`navbar-menu ${isMenuOpen ? 'is-active' : ''} `}
        >
          <div className="navbar-start">
            <Link className="navbar-item" to="/" onClick={toggleMenu}>
              <span className="icon-text">
                <span className="icon">
                  <HomeIcon />
                </span>{' '}
                <span>Úvod</span>
              </span>
            </Link>{' '}
            <Link className="navbar-item" to="/sluzby/" onClick={toggleMenu}>
              <span className="icon-text">
                <span className="icon">
                  <ServiceIcon />
                </span>{' '}
                <span>Služby</span>
              </span>
            </Link>
            {/* <Link
              className="navbar-item"
              to="/realizovane-zakazky/"
              onClick={toggleMenu}
            >
              <span className="icon-text">
                <span className="icon">
                  <CheckIcon />
                </span>{' '}
                <span>Realizované zakázky</span>
              </span>
            </Link> */}
            <Link className="navbar-item" to="/obsah/" onClick={toggleMenu}>
              <span className="icon-text">
                <span className="icon">
                  <ToolboxIcon />
                </span>{' '}
                <span>Nástroje a inspirace</span>
              </span>
            </Link>
            {/* <Link className="navbar-item" to="/trigger/" onClick={toggleMenu}>
              <span className="icon-text">
                <span className="icon">
                  <MedalIcon />
                </span>{' '}
                <span>Komunita Trigger</span>
                <span className="index-text is-small">Nové!</span>
              </span>
            </Link> */}
            <Link
              className="navbar-item"
              to="/nocode-vzdelavani/"
              onClick={toggleMenu}
            >
              <span className="icon-text">
                <span className="icon">
                  <MedalIcon />
                </span>{' '}
                <span>No-code vzdělávání</span>
                <span className="index-text is-small">Nové!</span>
              </span>
            </Link>
            <Link
              className="navbar-item"
              to="/o-workflow/"
              onClick={toggleMenu}
            >
              <span className="icon-text">
                <span className="icon">
                  <UsersIcon />
                </span>{' '}
                <span>O nás</span>
              </span>
            </Link>
            <Link className="navbar-item" to="/kontakt/" onClick={toggleMenu}>
              <span className="icon-text">
                <span className="icon">
                  <PhoneIcon />
                </span>{' '}
                <span>Kontakt</span>
              </span>
            </Link>
          </div>

          <div className="navbar-end">
            {' '}
            <div className="navbar-item">
              <Link
                className="button is-info"
                to="/sluzby/workflow-konzultace/?variant=sos"
                onClick={toggleMenu}
              >
                <span className="icon-text">
                  <span>Objednat konzultaci</span>{' '}
                  <span className="icon">
                    <Icon SvgIcon={ArrowRightIcon} />
                  </span>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Nav;
